import React from 'react';
import styled from 'styled-components';

import { 
    FaHome,
    FaObjectGroup,
    FaLeaf,
    FaBuilding,
    FaDiceD6,
    FaShapes,
    FaLightbulb
} from "react-icons/fa";

// ChakraIcons
import { 
    ChevronRightIcon,
    ChevronLeftIcon
} from '@chakra-ui/icons'

const ItemScroll = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 90px;
    background-color: #FFF;
    border-radius: 90px;
    position: relative;

    @media (max-width: 1300px) {
        width: 100%;
    }
`;

const ScrollButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 999px;
    border: 1px solid #CCC;
    background-color: #EEE;
`;

const ItensDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    gap: 15px;

    @media (max-width: 1076px) {
        width: 50%;
    }
`;

const Item = styled.div`
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    transition: ease-in-out all 0.15s;

    &:hover {
        background: #f3f3f3;
        cursor: pointer;
        transition: ease-in-out all 0.15s;
    }
`;

const ItemText = styled.span`
    font-size: 10px;
    text-align: center;
`;

export default function ItemScrolling() {

    const icons = {
        FaHome,
        FaObjectGroup,
        FaLeaf,
        FaBuilding,
        FaDiceD6,
        FaShapes,
        FaLightbulb
    };    

    const ScrollServices = [
        { 'name': 'Arquitetura', 'icon': 'FaHome', 'itemId': 1 },
        { 'name': 'Design de Interiores', 'icon': 'FaObjectGroup', 'itemId': 2 },
        { 'name': 'Paisagismo', 'icon': 'FaLeaf', 'itemId': 3 },
        { 'name': 'Construtoras', 'icon': 'FaBuilding', 'itemId': 4 },
        { 'name': 'Modelagem 3D', 'icon': 'FaDiceD6', 'itemId': 5 },
        { 'name': 'Móveis Planejados', 'icon': 'FaShapes', 'itemId': 6 },
        { 'name': 'Luminotecnia', 'icon': 'FaLightbulb', 'itemId': 7 }
    ];

    const handleNavigationWithId = async (id) => {
        sessionStorage.setItem('[zameed]navigationItem', id);
    }

    return (
        <ItemScroll>
            {/* Navegação para esquerda */}
            <ScrollButton style={{ position: 'absolute', left: '25px', top: '50%', transform: 'translateY(-50%)' }}>
                <ChevronLeftIcon />
            </ScrollButton>

            {/* Itens */}
            <ItensDiv>
                {ScrollServices.map((item, index) => {
                    const IconComponent = icons[item.icon];

                    return (
                        <Item key={index} onClick={() => handleNavigationWithId(item.itemId)}>
                            <div style={{ width: '100%', height: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <IconComponent size={24} color={'#666465'} />
                            </div>
                            <div style={{ width: '100%', height: '40%', display: 'flex', justifyContent: 'center', alignItems: 'start', fontFamily: 'Archivo', fontWeight: 600 }}>
                                <ItemText>{item.name}</ItemText>
                            </div>
                        </Item>
                    );
                })}
            </ItensDiv>

            {/* Navegação para direita */}
            <ScrollButton style={{ position: 'absolute', right: '25px', top: '50%', transform: 'translateY(-50%)' }}>
                <ChevronRightIcon />
            </ScrollButton>
        </ItemScroll>
    );
}
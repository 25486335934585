import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import axios from 'axios';

import {
    Button
} from '@chakra-ui/react'

// image
import Background from '../../Assets/img/background.jpg';

// components
import LoginUserComponent from '../../components/Login';
import RegisterUserComponent from '../../components/Register';

const LoginComponent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    background: #FFF;
`;

const LeftComponent = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    max-height: 100%;
    padding: 115px 115px;
    overflow-y: auto;
`;

const RightComponent = styled.div`
    width: 50%;
    height: 100%;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
`;

const TitleComponent = styled.h1`
    font-family: 'Archivo', sans-serif;
    font-weight: 600;
    font-size: 48px;
`;

const TextHelperComponent = styled.span`
    font-family: 'Archivo', sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #A3A3A3;
`;

const DontHaveAccountText = styled.span`
    font-family: 'Archivo', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    margin-top: 20px;
`;

const SpaceDivider = styled.div`
    width: 90%;
    padding: 40px 0;
`;

export default function Login() {
    const [isLoading, setIsLoading] = useState(false);
    const [switchMethod, setSwitchMethod] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
        firstName: '',
        lastName: '',
    });

    const navigate = useNavigate();

    function formatPhoneNumber(phone) {
        phone = phone.replace(/\D/g, '');
    
        return `+55${phone}`;
    }    

    // Autenticação
    async function handleSubmit() {
        // Ativar loader
        setIsLoading(true);

        if (switchMethod) {
            // Handle Register
            if (formData.password !== formData.confirmPassword) {
                toast.error('Senha incorreta, tente novamente.');
                return;
            }

            const formattedPhone = formatPhoneNumber(formData.phone);

            try{
                const response = await axios('http://indecisive-grape-vanilla.glitch.me/api/v1/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({
                        email: formData.email,
                        password: formData.password,
                        phone: formattedPhone,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                    })
                });

                if(response.data.message === 'Usuário registrado com sucesso'){
                    setIsLoading(false);
                    setSwitchMethod(false);
                    toast.success(response.data.message);
                } else if(response.data.message === 'The email address is already in use by another account.') {
                    setIsLoading(false);
                    toast.warning('Essa conta já está em uso, tente usar outro endereço de email.');
                } else {
                    setIsLoading(false);
                    toast.error('Algo deu errado, tente novamente.');
                }
            } catch (error) {
                console.error(error);
                toast.error('Erro ao registrar. Tente novamente mais tarde.');
                setIsLoading(false);
            }
        } else {
            try{
                // Handle Login
                const response = await axios('https://indecisive-grape-vanilla.glitch.me/api/v1/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({
                        email: formData.email,
                        password: formData.password,
                    })
                });

                if(response.data.message === 'Credenciais inválidas'){
                    setIsLoading(false);
                    return alert(response.data.message);
                }

                if(response.data.code === 200){
                    toast.info('Aguarde, autenticando-se...');

                    // Armazenar credencias de segurança
                    sessionStorage.setItem('refreshToken', response.data.auth.refreshToken);
                    sessionStorage.setItem('accessToken', response.data.auth.accessToken);
                    sessionStorage.setItem('expirationTime', response.data.auth.expirationTime);
                    localStorage.setItem('email', response.data.data.email);
                    localStorage.setItem('firstName', response.data.data.firstName);
                    localStorage.setItem('lastName', response.data.data.lastName);
                    localStorage.setItem('uid', response.data.data.uid);
                    localStorage.setItem('descProfile', response.data.data.description);
                    localStorage.setItem('aboutMe', response.data.data.about);

                    // Redirecionar
                    navigate('/home');
                }
            } catch (error) {
                if(error.response.status === 401){
                    toast.error('Credencias inválidas');
                    setIsLoading(false);
                } else {
                    console.error(error)
                    toast.error('Falha ao integrar-se com a ZameedAPI');
                    setIsLoading(false);
                }
            }
        }
    }

    // Reset data
    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
    }, [])

    return (
        <LoginComponent>
            <LeftComponent>
                <TitleComponent>
                    Bem vindo!
                </TitleComponent>
                <TextHelperComponent>
                    Entre com suas informações nos campos abaixo para entrar.
                </TextHelperComponent>

                {/* Form controls */}
                <SpaceDivider>
                    {
                        !switchMethod ?
                        <LoginUserComponent formData={formData} setFormData={setFormData} />
                        :
                        <RegisterUserComponent formData={formData} setFormData={setFormData} />
                    }
                </SpaceDivider>

                <Button 
                    isLoading={isLoading}
                    colorScheme='orange' 
                    style={{ width: '90%', height: 60, padding: '20px 0' }}
                    onClick={handleSubmit}
                >
                    {
                        !switchMethod ?
                        'ENTRAR'
                        :
                        'REGISTRAR'
                    }
                </Button>

                {/* Não tem uma conta */}
                {
                    !switchMethod &&
                    <DontHaveAccountText>
                        Não tem uma conta? <span onClick={() => setSwitchMethod(!switchMethod)} style={{ color: 'var(--chakra-colors-orange-500)', fontWeight: 600, marginLeft: 10, cursor: 'pointer' }}>Cadastre-se de graça</span>
                    </DontHaveAccountText>
                }
            </LeftComponent>
            <RightComponent />
            <ToastContainer />
        </LoginComponent>
    );
}
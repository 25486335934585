import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../Utils/database/firebase";

import { 
    FiDroplet,
    FiCheck,
    FiUserPlus,
    FiAlertCircle 
} from "react-icons/fi";

import { 
    Avatar,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    Tabs, 
    TabList, 
    Tab, 
    Tag,
    TagLabel,
    TagLeftIcon,
} from '@chakra-ui/react'

const VisualizationComponent = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 60px;
    max-height: 100%;
    overflow-y: auto;
    background: #FFF;
`;

const UserComponent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 55vh;
    position: relative;
`;

const BannerUserDefault = styled.div`
    width: 100%;
    height: 50vh;
    background: rgb(124,63,0);
    background: linear-gradient(90deg, rgba(124,63,0,1) 0%, rgba(217,243,255,1) 0%, rgba(173,234,253,1) 50%, rgba(50,210,255,1) 100%);
`;

const UserInformation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const UserAvatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    background: #FFF;
    border-radius: 999px;
    position: absolute;
    top: 110px;
    left: 25px;
    padding: 5px;
`;

const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 25px;
    margin-top: 78px;
`;

const UserNameAndDescription = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    height: auto;
    gap: 10px;
`;

const UsernameText = styled.span`
    font-size: 18px;
    font-weight: 600;
    font-family: 'Archivo', sans-serif;
`;

const UsernameDescription = styled.span`
    font-size: 14px;
    font-weight: 400;
    font-family: 'Archivo', sans-serif;
    color: #A3A3A3;
    margin-top: 2px;
`;

const UserTag = styled.span`
    font-size: 14px;
    font-weight: 400;
    font-family: 'Archivo', sans-serif;
    color: #8b9d99;
`;

const UserFollowers = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 250px;
    height: auto;
`;

const FollowersText = styled.span`
    font-size: 14px;
    font-weight: 600;
    font-family: 'Archivo', sans-serif;
    color: #000;
`;

const DefaultText = styled.span`
    font-size: 14px;
    font-weight: 400;
    font-family: 'Archivo', sans-serif;
    color: #8b9d99;
    margin-left: 3px;
`;

const ProfileButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid #32d2ff;
    color: #32d2ff;
    position: absolute;
    right: 25px;
    margin-top: 25px;
    padding: 5px 20px;
    border-radius: 100px;
    font-family: 'Archivo', sans-serif;
    font-weight: 600;
    background: none;
    transition: ease-in 0.1s;

    &:hover {
        background: #32d2ff;
        color: #FFF;
        transition: ease-in 0.1s;
    }
`;

export default function Visualization() {
    const { id } = useParams();
    const [userData, setUserData] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const [followersCount, setFollowersCount] = useState(0);

    useEffect(() => {
        const fetchUserData = async () => {
          if (id) {
            const userDoc = doc(db, "users", id);
            const userSnap = await getDoc(userDoc);
            if (userSnap.exists()) {
              setUserData(userSnap.data());
            } else {
              console.log("Usuário não encontrado com esse UID.");
            }
          }
        };

        const checkIfFollowing = async () => {
            const uid = localStorage.getItem('uid');
            if (uid && id) {
                const followingCollection = collection(db, "users", uid, "following");
                const q = query(followingCollection, where("followingId", "==", id));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    setIsFollowing(true);
                }
            }
        };

        const fetchFollowersCount = async () => {
            if (id) {
                const followersCollection = collection(db, "users", id, "followers");
                const followersSnapshot = await getDocs(followersCollection);
                
                followersSnapshot.forEach((doc) => {
                    //console.log("Seguidor encontrado:", doc.id, doc.data());
                });

                setFollowersCount(followersSnapshot.size);
            }
        };
        
    
        fetchUserData();
        checkIfFollowing();
        fetchFollowersCount();
    }, [id]);

    const handleFollowUser = async () => {
        try {
            const uid = localStorage.getItem('uid');
            const url = isFollowing 
                ? 'https://indecisive-grape-vanilla.glitch.me/api/v1/unfollow/user'
                : 'https://indecisive-grape-vanilla.glitch.me/api/v1/follow/user';
            
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: uid, followingId: id }),
            });

            if (response.ok) {
                setIsFollowing(!isFollowing);
            }
        } catch (error) {
            console.error(error);
        }
    }

    if (!userData) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100%', padding: '75px 45px', backgroundColor: '#FFF', gap: 10 }}>
                <FiAlertCircle size={128} />
                <span style={{ fontFamily: 'Archivo', fontSize: 24, fontWeight: '700' }}>Usuário não encontrado</span>
                <span style={{ fontFamily: 'Archivo', fontSize: 18 }}>Esse usuário foi excluido ou é inexistente, tente novamente mais tarde.</span>
            </div>
        );
    }

    return (
        <>
            <VisualizationComponent>
                <UserComponent>
                    <BannerUserDefault />
                    <UserInformation>
                        <UserAvatar>
                            <Avatar name={userData.firstName + " " + userData.lastName} width='150px' height='150' />
                        </UserAvatar>
                        <UserDetails>
                            {
                                userData.isBeta &&
                                <Tag size='sm' colorScheme='cyan' style={{ width: 70 }}>
                                    <TagLeftIcon as={FiDroplet} />
                                    <TagLabel>BETA</TagLabel>
                                </Tag>
                            }
                            <UserNameAndDescription>
                                <UsernameText>{userData.firstName + " " + userData.lastName}</UsernameText>
                                <UsernameDescription>{userData.description}</UsernameDescription>
                            </UserNameAndDescription>
                            <UserTag>
                                {
                                    userData &&
                                    `@${userData.firstName}`
                                }
                            </UserTag>
                            <UserFollowers>
                                {
                                    userData &&
                                    <FollowersText>
                                        {followersCount}
                                        <DefaultText>
                                            Seguidores
                                        </DefaultText>
                                    </FollowersText>
                                }
                                {
                                    userData &&
                                    <FollowersText>
                                        0
                                        <DefaultText>
                                            Clientes atendidos
                                        </DefaultText>
                                    </FollowersText>
                                }
                            </UserFollowers>
                        </UserDetails>
                        {
                            localStorage.getItem('firstName') === userData.firstName && localStorage.getItem('lastName') === userData.lastName ?
                            <Popover>
                                <PopoverTrigger>
                                    <ProfileButton>
                                        Visualizar meu ID
                                    </ProfileButton>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>Aqui está seu ID</PopoverHeader>
                                    <PopoverBody style={{ fontWeight: '800', fontFamily: 'Archivo' }}>{localStorage.getItem('uid')}</PopoverBody>
                                </PopoverContent>
                            </Popover>
                            :
                            <ProfileButton onClick={handleFollowUser}>
                                {isFollowing ? <FiCheck style={{ marginRight: 5 }} /> : <FiUserPlus style={{ marginRight: 5 }} />}
                                {isFollowing ? "Seguindo" : "Seguir"}
                            </ProfileButton>
                        }
                    </UserInformation>
                </UserComponent>
                <Tabs>
                    <TabList>
                        <Tab style={{ fontFamily: 'Archivo' }}>Posts</Tab>
                        <Tab style={{ fontFamily: 'Archivo' }}>Comentados</Tab>
                        <Tab style={{ fontFamily: 'Archivo' }}>Curtidas</Tab>
                        <Tab style={{ fontFamily: 'Archivo' }}>Projetos</Tab>
                    </TabList>
                </Tabs>
            </VisualizationComponent>
            <ToastContainer />
        </>
    )
}
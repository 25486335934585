import React, { useState } from "react";
import { Input, InputGroup, InputLeftAddon, InputRightElement, Button } from '@chakra-ui/react'
import { Link } from "react-router-dom";
import styled from "styled-components";

import { FaUser } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { 
    FiEye,
    FiEyeOff 
} from "react-icons/fi";
import { FaUnlock } from "react-icons/fa6";

const AdminComponent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Box = styled.div`
    width: 350px;
    height: 120px;
    border-radius: 6px;
    background: #FFF;
    border: 1px solid #CCC;
`;

const BoxHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #f7f5fa;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid #CCC;
`;

const SpanLoginAdm = styled.span`
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-family: 'Archivo', sans-serif;
`;

const BoxInsideLogin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
    position: relative;
`;

export default function Admin() {
    const [user, setUser] = useState('');
    const [key, setKey] = useState('');
    const [show, setShow] = useState(false)

    const handleClick = () => setShow(!show)

    return (
        <>
            <AdminComponent>
                <Box>
                    <BoxHeader>
                        <SpanLoginAdm>ADMINISTRAÇÃO</SpanLoginAdm>
                    </BoxHeader>
                    <BoxInsideLogin>
                        <Link to='/admin/manage' style={{ position: 'absolute', bottom: 20, width: '100%', fontFamily: 'Archivo', fontSize: 12, padding: '0 15px' }}>
                            <Button leftIcon={<FaUnlock />} colorScheme='teal' variant='solid' style={{ width: '100%', fontFamily: 'Archivo', fontSize: 12 }}>
                                Login
                            </Button>
                        </Link>
                    </BoxInsideLogin>
                </Box>
            </AdminComponent>
        </>
    )
}
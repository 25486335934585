import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { 
    FiUser,
    FiSettings  
} from "react-icons/fi";

const SidebarComponent = styled.div`
    display: flex;
    flex-direction: column;
    width: 255px;
    height: 100vh;
    background-color: #4a4e58;
`;

const HeaderSidebar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 85px;
    background-color: #565b67;
`;

const TextHeader = styled.span`
    font-size: 32px;
    font-weight: 500;
    color: #FFF;
    font-family: 'Archivo', sans-serif;
`;

const ButtonSidebar = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    border: none;
    width: 100%;
    height: 45px;
    transition: .2s ease-in-out;
    border-left: 3px solid #4a4e58;
    padding: 0 15px;
    color: #FFF;
    font-weight: 500;
    font-family: 'Archivo', sans-serif;

    &:hover {
        background-color: #373a42;
        transition: .2s ease-in-out;
        border-left: 3px solid #1d9cba;
    }
`;

export default function SidebarAdm({ item, setItem }) {
    return (
        <SidebarComponent>
            <HeaderSidebar>
                <TextHeader>ADMIN</TextHeader>
            </HeaderSidebar>
            <ButtonSidebar 
                onClick={() => setItem('manager')} 
                style={{ borderLeft: item === 'manager' && '3px solid #1d9cba', backgroundColor: item === 'manager' && '#373a42'  }}
            >
                <FiSettings style={{ marginRight: 8 }} color="#7387a1" />
                Gerenciar
            </ButtonSidebar>
        </SidebarComponent>
    )
}
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { FiMoreVertical, FiDroplet } from "react-icons/fi";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Tag,
    TagLabel,
    TagLeftIcon,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react'

const MainAdminComponent = styled.div`
    width: 85%;
    height: 100vh;
`;

const ManageUsers = styled.div`
    width: 100%;
    height: 100%;
    padding: 25px 45px;
    display: flex;
    flex-direction: column;
`;

const HeaderDesc = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
`;

const HeaderTitle = styled.h1`
    font-size: 24px;
    font-family: 'Archivo', sans-serif;
`;

const HeaderDescription = styled.span`
    font-size: 14px;
    font-family: 'Archivo', sans-serif;
    font-weight: 400;
    color: #666;
`;

export default function MainAdmin({ selectedItem }) {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const searchUsers = async () => {
            try{
                const response = await fetch('https://indecisive-grape-vanilla.glitch.me/api/v1/users');
                const data = await response.json();
                
                setUsers(data)
            } catch (error) {
                console.error('Algo deu errado ao buscar os usuários:', error);
            }
        }

        searchUsers();
    }, []);

    const handleToggleAdmin = async (userId, isAdmin) => {
        try {
            const response = await fetch('https://indecisive-grape-vanilla.glitch.me/api/v1/users/admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: userId, isAdmin: !isAdmin }),
            });
            
            if (response.ok) {
                const updatedUser = await response.json();
                setUsers(users.map(user => user.id === userId ? updatedUser : user));
                toast.success('O status de Admin foi atualizado com sucesso!');
            } else {
                console.error('Erro ao atualizar status de admin');
                toast.error('Erro ao atualizar o status do usuário.');
            }
        } catch (error) {
            console.error('Erro ao atualizar status de admin:', error);
            toast.error('Erro ao atualizar o status do usuário.');
        }
    };

    const handleToggleBeta = async (userId, isBeta) => {
        try {
            const response = await fetch('https://indecisive-grape-vanilla.glitch.me/api/v1/users/beta', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: userId, isBeta: !isBeta }),
            });
            
            if (response.ok) {
                const updatedUser = await response.json();
                setUsers(users.map(user => user.id === userId ? updatedUser : user));
                toast.success('O status de Beta foi atualizado com sucesso!');
            } else {
                console.error('Erro ao atualizar status de admin');
                toast.error('Erro ao atualizar o status do usuário.');
            }
        } catch (error) {
            console.error('Erro ao atualizar status de admin:', error);
            toast.error('Erro ao atualizar o status do usuário.');
        }
    };

    return (
        <MainAdminComponent>
            {
                selectedItem === 'manager' &&
                <ManageUsers>
                    <HeaderDesc>
                        <HeaderTitle>Gerenciamento dos Usuários</HeaderTitle>
                        <HeaderDescription>Faça o gerenciamento dos usuários no site em tempo real.</HeaderDescription>
                    </HeaderDesc>
                    <TableContainer>
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th style={{ fontFamily: 'Archivo', fontSize: 10, fontWeight: '600' }}>Usuário</Th>
                                    <Th style={{ fontFamily: 'Archivo', fontSize: 10, fontWeight: '600' }}>E-mail</Th>
                                    <Th style={{ fontFamily: 'Archivo', fontSize: 10, fontWeight: '600' }}>Telefone</Th>
                                    <Th style={{ fontFamily: 'Archivo', fontSize: 10, fontWeight: '600' }}>Última entrada</Th>
                                    <Th style={{ fontFamily: 'Archivo', fontSize: 10, fontWeight: '600' }}>Criado em</Th>
                                    <Th style={{ fontFamily: 'Archivo', fontSize: 10, fontWeight: '600' }}>Admin</Th>
                                    <Th style={{ fontFamily: 'Archivo', fontSize: 10, fontWeight: '600' }}>Beta</Th>
                                    <Th style={{ fontFamily: 'Archivo', fontSize: 10, fontWeight: '600' }}>Opções</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    users.map((item, index) => (
                                        <Tr 
                                            key={index}
                                            sx={{
                                                transition: '.1s ease',
                                                borderLeft: '3px solid #FFF',
                                                _hover: {
                                                    backgroundColor: '#edeff5',
                                                    transition: '.1s ease-in',
                                                    borderLeft: '3px solid #1ca1c1'
                                                },
                                            }}
                                        >
                                            <Td style={{ fontSize: 12, fontWeight: '600' }}>{item.firstName + " " + item.lastName}</Td>
                                            <Td style={{ fontSize: 12, fontWeight: '600' }}>{item.email}</Td>
                                            <Td style={{ fontSize: 12, fontWeight: '600' }}>{item.phone}</Td>
                                            <Td style={{ fontSize: 12, fontWeight: '600' }}>
                                                {
                                                    item.lastLogin ?
                                                    new Date(item.lastLogin).toLocaleDateString('pt-BR') + " às " + new Date(item.lastLogin).toLocaleTimeString('pt-BR')
                                                    :
                                                    'Não registrado'
                                                }
                                            </Td>
                                            <Td style={{ fontSize: 12, fontWeight: '600' }}>
                                                {
                                                    item.createdAt ?
                                                    new Date(item.createdAt).toLocaleDateString('pt-BR')
                                                    :
                                                    'Não registrado'
                                                }
                                            </Td>
                                            <Td>
                                                {
                                                    item.isAdmin &&
                                                    <Tag size='sm' colorScheme='green'>
                                                        <TagLeftIcon as={MdOutlineAdminPanelSettings} />
                                                        <TagLabel>ADMIN</TagLabel>
                                                    </Tag>
                                                }
                                            </Td>
                                            <Td>
                                                {
                                                    item.isBeta &&
                                                    <Tag size='sm' colorScheme='cyan'>
                                                        <TagLeftIcon as={FiDroplet} />
                                                        <TagLabel>BETA</TagLabel>
                                                    </Tag>
                                                }
                                            </Td>
                                            <Td>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    <Menu>
                                                        <MenuButton style={{ backgroundColor: 'transparent' }}>
                                                            <FiMoreVertical cursor='pointer' />
                                                        </MenuButton>
                                                        <MenuList>
                                                            <MenuItem 
                                                                style={{ fontSize: 14, fontWeight: '500', fontFamily: 'Archivo' }}
                                                                onClick={() => handleToggleAdmin(item.id, item.isAdmin)}
                                                            >
                                                                {
                                                                    !item.isAdmin ?
                                                                    'Tornar Admin'
                                                                    :
                                                                    'Revogar Admin'
                                                                }
                                                            </MenuItem>
                                                            <Link to={`/profile/${item.id}`}>
                                                                <MenuItem style={{ fontSize: 14, fontWeight: '500', fontFamily: 'Archivo' }}>
                                                                    Visualizar Perfil
                                                                </MenuItem>
                                                            </Link>
                                                            <MenuItem 
                                                                style={{ fontSize: 14, fontWeight: '500', fontFamily: 'Archivo' }}
                                                                onClick={() => handleToggleBeta(item.id, item.isBeta)}
                                                            >
                                                                {
                                                                    !item.isBeta ?
                                                                    <>
                                                                        <span>Tornar</span>
                                                                        <Tag size='sm' colorScheme='blue' marginLeft={2}>
                                                                            <TagLeftIcon as={FiDroplet} />
                                                                            <TagLabel>BETA</TagLabel>
                                                                        </Tag>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <span>Remover</span>
                                                                        <Tag size='sm' colorScheme='blue' marginLeft={2}>
                                                                            <TagLeftIcon as={FiDroplet} />
                                                                            <TagLabel>BETA</TagLabel>
                                                                        </Tag>
                                                                    </>
                                                                }
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </div>
                                            </Td>
                                        </Tr>
                                    ))
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </ManageUsers>
            }
            {
                !selectedItem && 
                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <MdOutlineAdminPanelSettings size={128} />
                    <span style={{ fontFamily: 'Archivo', fontSize: 18, fontWeight: '500', marginTop: 10 }}>Selecione uma opção na barra lateral</span>
                </div>
            }
            <ToastContainer />
        </MainAdminComponent>
    )
}
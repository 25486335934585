import * as React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'

// Layout
import Layout from './Layout';

// Pages
import Home from '../src/Pages/Home/index';
import Login from './Pages/Login';
import Profile from './Pages/Profile';
import Admin from './Pages/Admin';
import AdminPage from './Pages/Admin/src';
import Visualization from './Pages/Profile/Visualization';

// Protected Route
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Layout><Home /></Layout>} />
          <Route path="/profile" element={<Layout><Profile /></Layout>} />
          <Route path="/profile/:id" element={<Layout><Visualization /></Layout>} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/manage" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );

}

export default App;

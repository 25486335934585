import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBsvsEWqkOZUuRR8dmVyTWgt2_f-2LUo4U",
    authDomain: "zameed-f036f.firebaseapp.com",
    projectId: "zameed-f036f",
    storageBucket: "zameed-f036f.appspot.com",
    messagingSenderId: "195829519287",
    appId: "1:195829519287:web:7f1ea32f7850383f6f8e32",
    measurementId: "G-6KBLBTV686"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };

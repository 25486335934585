import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../Utils/database/firebase";

// ChakraIcons
import { 
    Search2Icon,
    HamburgerIcon
} from '@chakra-ui/icons'

import { 
    PiBellFill 
} from "react-icons/pi";

// ChakraUI
import { 
    Input,
    InputGroup,
    InputRightAddon,
    Avatar,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    position,
} from '@chakra-ui/react'

// Logo
import Logo from '../../Assets/img/zlogo.svg';

const HeaderComponent = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: #f9fbfc;
    margin: 0;
    padding: 0 35px;
    z-index: 1000;
`;

const LogoImg = styled.img`
    width: 45px;
    height: 45px;
`;

const UserComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
`;

export default function Header() {
    const [followers, setFollowers] = useState([])

    useEffect(() => {
        const fetchFollowers = async () => {
            const uid = localStorage.getItem('uid');
            if (uid) {
                try {
                    const followersCollection = collection(db, "users", uid, "followers");
                    const querySnapshot = await getDocs(followersCollection);
                    
                    if (!querySnapshot.empty) {
                        const followerIds = querySnapshot.docs.map(doc => doc.id);
                        const followersData = await Promise.all(followerIds.map(async (followerId) => {
                            const userDocRef = doc(db, "users", followerId);
                            const userDoc = await getDoc(userDocRef);
                            return userDoc.exists() ? userDoc.data() : null;
                        }));
                        setFollowers(followersData.filter(data => data !== null));
                    }
                } catch (error) {
                    console.error("Erro ao buscar seguidores", error);
                }
            }
        };

        fetchFollowers();
    }, []);

    return (
        <HeaderComponent>
            <Link to='/home'>
                <LogoImg src={Logo} />
            </Link>
            <InputGroup style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Input placeholder='' size='md' style={{ width: 500, backgroundColor: '#FFF', color: '#000', borderRightColor: '#FFF', borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }} />
                <InputRightAddon style={{ backgroundColor: '#FFF', borderLeftColor: '#FFF', borderTopRightRadius: 5, borderBottomRightRadius: 5 }}>
                    <Search2Icon />
                </InputRightAddon>
            </InputGroup>
            <UserComponent>
                <Avatar name={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')} style={{ width: 35, height: 35, marginRight: 10 }} />
                <Popover trigger='hover'>
                    <PopoverTrigger>
                        <PiBellFill size={18} color='#656565' cursor='pointer' />
                    </PopoverTrigger>
                    <PopoverContent style={{ right: 250, top: 60 }}>
                        <PopoverHeader style={{ fontFamily: 'Archivo' }}>Notificações</PopoverHeader>
                        <PopoverBody style={{ maxHeight: '50%', overflowY: 'auto' }}>
                            {followers.length > 0 ? (
                                followers.map((follower, index) => (
                                    <div key={index} style={{ marginBottom: 10 }}>
                                        <Avatar name={follower.firstName + " " + follower.lastName} style={{ width: 30, height: 30, marginRight: 10 }} />
                                        <span style={{ fontFamily: 'Archivo', fontSize: 12, fontWeight: '600' }}>{follower.firstName + " " + follower.lastName} <span style={{ fontWeight: '400' }}>começou a seguir você.</span></span>
                                    </div>
                                ))
                            ) : (
                                <div>Não há nada por aqui...</div>
                            )}  
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </UserComponent>
        </HeaderComponent>
    );
}
import React, { useState } from "react";
import styled from "styled-components";

// Componentes da Admin
import SidebarAdm from "../components/Sidebar";
import MainAdmin from "../components/Main";

const AdminComponent = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background: #FFF;
`;

export default function AdminPage() {
    const [item, setItem] = useState('');

    return (
        <AdminComponent>
            <SidebarAdm item={item} setItem={setItem} />
            <MainAdmin selectedItem={item} />
        </AdminComponent>
    )
}
import React, { useState } from 'react';
import styled from 'styled-components';

// components
import NavigationProfile from '../../components/Profile/NavigationProfile';
import CustomProfile from '../../components/Profile/CustomProfile';

const ProfileComponent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-top: 60px;
    background: #FFF;
`;

export default function Profile() {
    return (
        <ProfileComponent>
            <NavigationProfile />
            <CustomProfile />
        </ProfileComponent>
    );
}
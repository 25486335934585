import React, { useState } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { TbSmartHome } from "react-icons/tb";
import { FiChevronRight } from "react-icons/fi";

// ChakraUI components
import { 
    Avatar,
    Button,
    Input,
    FormControl,
    FormLabel,
    FormHelperText,
    Textarea,
    ButtonGroup
} from '@chakra-ui/react';

const MainContent = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 25px 35px;
`;

const CustomBreadcrumb = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-family: 'Archivo', sans-serif;
    color: #4f5a73;
`;

const HeaderDescription = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70px;
    margin-top: 20px;
    border-bottom: 1px solid #EEE;
`;

const HeaderTitleText = styled.span`
    font-size: 24px;
    font-weight: 600;
    font-family: 'Archivo', sans-serif;
    height: 30px;
`;

const HeaderDescriptionText = styled.span`
    font-size: 16px;
    font-weight: 400;
    font-family: 'Archivo', sans-serif;
    color: #8f97a7;
`;

const InputsGlobalComponent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70%;
    padding: 1.2rem 0;
    gap: 1.5rem;
`;

const InputProfileAvatar = styled.div`
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
`;

export default function CustomProfile() {
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState(localStorage.getItem('descProfile') || null);
    const [about, setAbout] = useState(localStorage.getItem('aboutMe') || null);

    async function editProfile() {
        setLoading(true);

        setTimeout(async () => {
            try{
                const response = await fetch('https://indecisive-grape-vanilla.glitch.me/api/v1/users/edit', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: localStorage.getItem('uid'), description: description, about: about }),
                });

                if(response.ok){
                    localStorage.setItem('descProfile', description);
                    localStorage.setItem('aboutMe', about);
                    setLoading(false);
                    toast.success('Suas informações foram alteradas!');
                } else {
                    console.error('Erro ao atualizar as informações');
                    toast.error('Erro ao atualizar as suas informações');
                }
            } catch (error) {
                console.error('Erro ao atualizar as informações', error);
                toast.error('Algo deu errado, erro interno do servidor.');
            }
        }, 5000)
    }

    return(
        <MainContent>
            <CustomBreadcrumb>
                <a href='/home' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <TbSmartHome color="#4f5a73" />
                    <span style={{ fontFamily: 'Archivo', fontSize: 16, marginLeft: 5 }}>Home</span>
                </a>
                <FiChevronRight size={18} />
                <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span style={{ fontFamily: 'Archivo', fontSize: 16, marginLeft: 5 }}>Configurações</span>
                </a>
                <FiChevronRight size={18} />
                <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span style={{ fontFamily: 'Archivo', fontSize: 16, marginLeft: 5, color: '#181d27', fontWeight: 600 }}>Perfil</span>
                </a>
            </CustomBreadcrumb>

            <HeaderDescription>
                <HeaderTitleText>Perfil</HeaderTitleText>
                <HeaderDescriptionText>Gerencie as configurações do seu Perfil.</HeaderDescriptionText>
            </HeaderDescription>

            <InputsGlobalComponent>
                <InputProfileAvatar>
                    <Avatar size='lg' name={localStorage.getItem('firstName')} style={{ userSelect: 'none' }} />
                    <Button style={{ backgroundColor: '#2d95f0', color: '#FFF', fontFamily: 'Archivo', fontSize: 14, height: 35 }}>Mudar foto</Button>
                    <Button colorScheme='red' variant='outline' style={{ fontFamily: 'Archivo', fontSize: 14, height: 35 }}>Remover foto</Button>
                </InputProfileAvatar>

                {/* Form Control */}
                <FormControl>
                    <FormLabel style={{ fontFamily: 'Archivo', height: 14, fontSize: 14 }}>Nome</FormLabel>
                    <Input 
                        type='text' 
                        style={{ fontFamily: 'Archivo'}}
                        disabled={true} 
                        value={localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName')}
                    />
                    <FormHelperText style={{ fontFamily: 'Archivo' }}>Mudança indisponível</FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel style={{ fontFamily: 'Archivo', height: 14, fontSize: 14 }}>Descrição</FormLabel>
                    <Input 
                        type='text' 
                        style={{ fontFamily: 'Archivo'}}
                        value={description}
                        onChange={event => setDescription(event.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel style={{ fontFamily: 'Archivo', height: 14, fontSize: 14 }}>E-mail</FormLabel>
                    <Input 
                        type='email' 
                        style={{ fontFamily: 'Archivo'}} 
                        disabled={true} 
                        value={localStorage.getItem('email')}
                    />
                    <FormHelperText style={{ fontFamily: 'Archivo' }}>Mudança indisponível</FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel style={{ fontFamily: 'Archivo', height: 14, fontSize: 14 }}>Diga um pouco sobre você...</FormLabel>
                    <Textarea 
                        style={{ fontFamily: 'Archivo', fontSize: 14 }}
                        value={about}
                        onChange={event => setAbout(event.target.value)}
                    />
                </FormControl>

                {/* Buttons Control */}
                <ButtonGroup variant='outline' spacing='6'>
                    <Button 
                        colorScheme='blue' 
                        style={{ fontFamily: 'Archivo', fontSize: 14 }}
                        isLoading={loading}
                        onClick={() => editProfile()}
                    >
                        Salvar alterações
                    </Button>
                    <Button 
                        style={{ fontFamily: 'Archivo', fontSize: 14 }}
                    >
                        Cancelar
                    </Button>
                </ButtonGroup>
            </InputsGlobalComponent>
            <ToastContainer />
        </MainContent>
    );
}
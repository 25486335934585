import React from 'react';
import styled from 'styled-components';

import {
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react'

export default function LoginUserComponent({ formData, setFormData }) {
    return (
        <>
            <FormControl>
                <FormLabel style={{ fontFamily: 'Archivo', height: 18 }}>Email</FormLabel>
                <Input 
                    type='email' 
                    value={formData.email} 
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })} 
                />
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
                <FormLabel style={{ fontFamily: 'Archivo', height: 18 }}>Senha</FormLabel>
                <Input 
                    type='password' 
                    value={formData.password} 
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })} 
                />
            </FormControl>
        </>
    );
}
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Spinner } from '@chakra-ui/react'

export default function ProtectedRoute({ children }) {
    const [isAdmin, setIsAdmin] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAdmin = async () => {
            try {
                const uid = localStorage.getItem('uid');

                const response = await fetch('https://indecisive-grape-vanilla.glitch.me/api/v1/admin', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ uid })
                });

                const data = await response.json();

                setIsAdmin(data.isAdmin);
            } catch (error) {
                console.error('Erro ao verificar admin:', error);
            } finally {
                setLoading(false);
            }
        };

        checkAdmin();
    }, []);

    if (loading) {
        return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spinner /></div>;
    }

    if (!isAdmin) {
        return <Navigate to="/admin" replace />;
    }

    return children;
}
import React from 'react';
import styled from 'styled-components';

import {
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react'

export default function RegisterUserComponent({ formData, setFormData }) {
    return (
        <>
            <FormControl>
                <FormLabel style={{ fontFamily: 'Archivo', height: 18 }}>Email</FormLabel>
                <Input 
                    type='email' 
                    value={formData.email} 
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })} 
                />
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
                <FormLabel style={{ fontFamily: 'Archivo', height: 18 }}>Senha</FormLabel>
                <Input 
                    type='password' 
                    value={formData.password} 
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })} 
                />
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
                <FormLabel style={{ fontFamily: 'Archivo', height: 18 }}>Confirme sua senha</FormLabel>
                <Input 
                    type='password' 
                    value={formData.confirmPassword} 
                    onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })} 
                />
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
                <FormLabel style={{ fontFamily: 'Archivo', height: 18 }}>Celular</FormLabel>
                <Input 
                    type='number' 
                    value={formData.phone} 
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })} 
                />
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
                <FormLabel style={{ fontFamily: 'Archivo', height: 18 }}>Nome</FormLabel>
                <Input 
                    type='text' 
                    value={formData.firstName} 
                    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })} 
                />
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
                <FormLabel style={{ fontFamily: 'Archivo', height: 18 }}>Sobrenome</FormLabel>
                <Input 
                    type='text' 
                    value={formData.lastName} 
                    onChange={(e) => setFormData({ ...formData, lastName: e.target.value })} 
                />
            </FormControl>
        </>
    );
}
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import { 
    Flex,
    Box,
    Avatar,
    Text,
    Tag,
    TagLabel,
    TagLeftIcon,
} from '@chakra-ui/react';

// Icons
import { FiThumbsUp, FiMessageCircle, FiSend, FiGlobe, FiDroplet } from "react-icons/fi";
import { IoHeart } from "react-icons/io5";

import { db } from '../../Utils/database/firebase';
import { doc, getDoc, Timestamp } from 'firebase/firestore';

const WholePostComponent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: #FFF;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
`;

const UserInfoComponent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 120px;
    padding: 35px;
`;

const CommentUser = styled.div`
    width: 100%;
    height: auto;
    padding: 0 35px 60px 35px;
`;

const CommentUserText = styled.span`
    font-family: 'Archivo', sans-serif;
    font-size: 16px;
    color: #000;
`;

const ActionsUserPostComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 35px;
`;

const ActionUserPost = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    font-family: 'Archivo', sans-serif;
    font-size: 14px;
`;

const UserPost = ({ text, description, author, timestamp, userId, isBeta }) => {
    const [isLiked, setIsLiked] = useState(false);

    const handleLikePost = () => {
        setIsLiked(!isLiked);
    }

    const parseTextWithLinks = (text) => {
        const urlPattern = /(https?:\/\/[^\s]+)/g;
        return text.split(urlPattern).map((part, index) => 
            urlPattern.test(part) ? <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: '#319795' }}>{part}</a> : part
        );
    };

    const timeSincePost = () => {
        if (!timestamp) {
            return 'Data inválida';
        }
    
        let date;
        if (timestamp instanceof Timestamp) {
            date = timestamp.toDate();
        } else if (timestamp instanceof Date) {
            date = timestamp;
        } else {
            date = new Date(timestamp);
        }
    
        const seconds = Math.floor((new Date() - date) / 1000);
        const intervals = [
            { label: "ano", seconds: 31536000 },
            { label: "mês", seconds: 2592000 },
            { label: "dia", seconds: 86400 },
            { label: "hora", seconds: 3600 },
            { label: "minuto", seconds: 60 },
            { label: "segundo", seconds: 1 }
        ];
    
        for (let i = 0; i < intervals.length; i++) {
            const interval = Math.floor(seconds / intervals[i].seconds);
            if (interval > 0) {
                return `Há ${interval} ${interval !== 1 ? intervals[i].label + 's' : intervals[i].label}`;
            }
        }
    
        return 'Agora';
    };

    return (
        <WholePostComponent>
            <UserInfoComponent>
                <Flex>
                    <Link to={'/profile/' + userId}>
                        <Avatar name={author} style={{ userSelect: 'none' }} />
                    </Link>
                    <Box ml='3'>
                        <Link to={'/profile/' + userId} style={{ display: 'flex', flexDirection: 'row' }}>
                            <Text fontWeight='bold' style={{ fontFamily: 'Archivo' }}>
                                {author}
                            </Text>
                            {
                                isBeta &&
                                <Tag size='sm' colorScheme='cyan' style={{ margin: '0 5px', padding: '0 1px 0 8px'}}>
                                    <TagLeftIcon as={FiDroplet} />
                                </Tag>
                            }
                        </Link>
                        <Text fontSize='sm' style={{ fontFamily: 'Archivo', color: '#666666' }}>
                            {
                                description === 'undefined' || description === undefined ?
                                ''
                                :
                                description
                            }
                        </Text>
                        <Text fontSize='sm' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontFamily: 'Archivo' }}>
                            {timeSincePost()}
                            <FiGlobe color='#666666' style={{ marginLeft: 5 }} />
                        </Text>
                    </Box>
                </Flex>
            </UserInfoComponent>

            <CommentUser>
                <CommentUserText>
                    {parseTextWithLinks(text)}
                </CommentUserText>
            </CommentUser>

            <ActionsUserPostComponent>
                <ActionUserPost onClick={handleLikePost}>
                    {
                        !isLiked ?
                        <FiThumbsUp />
                        :
                        <IoHeart color='#FF0000' size={18} />
                    }
                    <span style={{ marginTop: 4, marginLeft: 5 }}>Curtir</span>
                </ActionUserPost>
                <ActionUserPost>
                    <FiMessageCircle />
                    <span style={{ marginTop: 4, marginLeft: 5 }}>Comentar</span>
                </ActionUserPost>
                <ActionUserPost>
                    <FiSend />
                    <span style={{ marginTop: 4, marginLeft: 5 }}>Compartilhar</span>
                </ActionUserPost>
            </ActionsUserPostComponent>
        </WholePostComponent>
    );
};

export default UserPost;
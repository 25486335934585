import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../../Utils/database/firebase';

// Components
import ItemScrolling from '../ItemScrolling';
import UserComponent from '../UserComponent';
import UserPost from '../UserPost';
import NewPost from '../NewPost';

const HomeDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90%;
    margin-top: 60px;
`;

const LeftSide = styled.div`
    padding: 25px 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    height: auto;
    overflow-y: auto;
    gap: 20px
`;

const InsideScrollingLeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    height: 100%;
    padding: 25px 0;
    position: sticky;
    top: 0;
`;

const ComponentToCreateNewPost = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export default function HomeComponent() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'posts'), (snapshot) => {
            const postsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            const sortedPosts = postsData.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
    
            setPosts(sortedPosts);
        });
    
        return () => unsubscribe();
    }, []);

    return (
        <HomeDiv>
            <LeftSide>
                <InsideScrollingLeftSide>
                    <ItemScrolling />
                </InsideScrollingLeftSide>

                {/* Criar nova publicação */}
                <ComponentToCreateNewPost>
                    <NewPost />
                </ComponentToCreateNewPost>

                {/* POSTS */}
                {posts.map(post => (
                    <UserPost key={post.id} text={post.text} description={post.description} author={post.author} timestamp={post.timestamp} userId={post.userId} isBeta={post.isBeta} />
                ))}
            </LeftSide>
            <RightSide>
                <UserComponent />
            </RightSide>
        </HomeDiv>
    );
}
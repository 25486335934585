import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Components
import HomeComponent from '../../components/HomeComponent';

const DivComponent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
`;

export default function Home() {
    const navigate = useNavigate();

    useEffect(() => {
        const expirationTime = sessionStorage.getItem('expirationTime');
        if (expirationTime) {
            const currentTime = Date.now();
            if (currentTime > parseInt(expirationTime, 10)) {
                sessionStorage.removeItem('expirationTime');
                navigate('/');
            }
        } else {
            navigate('/');
        }
    }, [navigate]);

    return (
        <DivComponent>
            <HomeComponent />
        </DivComponent>
    );
}